<template>
    <form id="jk-events-listing__form" v-on:submit.prevent="submitForm" v-if="showFilters">
        <div>
            <div class="jk-events-listing__input-button-wrapper"> 
                <div class="jk-events-listing__form-filter-container env-m-bottom--large" v-show="filtersConfigured">
                    <div class="jk-events-listing__form-filter-content">
                        <div class="sv-row">
                            <div class="sv-column-3">                             
                                <date-picker/>
                            </div>
                            <div class="sv-column-3">
                                <dropdown-filter :name="'area'"></dropdown-filter>
                            </div>                            
                            <div class="sv-column-6">
                                <div class="jk-events-listing__form-query-input-wrapper jk-events-listing__form-filter-item">
                                    <label for="event-search-input" class="show-for-sr">label{{translation.searchFreeTextEventLabel}}</label>
                                    <input class="jk-events-listing__form-query-input" id="event-search-input" type="text" :placeholder="translation.searchFreeTextEventPlaceholder" v-model="currentQuery" v-on:input="onQueryChange()" aria-controls="jk-events-listing__hit-list">
                                </div>            
                            </div>            
                        </div>                    
                        <div class="sv-row env-m-top--medium">
                            <div class="sv-column-4">
                                <dropdown-filter :name="'category'"></dropdown-filter>
                            </div>
                            <div class="sv-column-4">
                                <dropdown-filter :name="'theme'"></dropdown-filter>
                            </div>                            
                            <div class="sv-column-4">
                                <dropdown-filter :name="'age'"></dropdown-filter>
                            </div>
                        </div>
                    </div>
                </div>                 
                <button type="submit" class="show-for-sr">
                    {{translation.search}}
                </button>                
            </div>        
        </div>        
    </form>
</template>

<script>
import Vue from 'vue';
import {DatePicker } from '../calendar-loader';

import DropdownFilter from "./DropdownFilter.vue";
import moment from 'moment';

var locale = document.documentElement.lang;
moment.locale(locale);

export default {
    name: 'searchForm',
	components: {
        DropdownFilter,
        DatePicker 
	},    
    data: function ()  {
        return {
            currentQuery: this.$store.getters.searchInfo.query,            
            queryInputDelayTimeout: null,              
        }
    },   
	computed: {        
        translation() { return this.$store.getters.translation },        
        showFilters: function(){
            return this.$store.state.showFilters;
        },       
        filtersConfigured: function(){
            return this.$store.state.filtersConfigured;
        },
        searching: function(){
            return this.$store.state.searching;
        },	   
    },
    methods: {
        search: function(searchString){
            this.$store.commit({
                type: 'setSearchInfo',
                payload: { 
                    query: searchString,
                    currentPage: 1,
                    timestamp: Date.now(),
                }
            });
            this.$router.push({query: this.$store.getters.urlQuery});
        },
        submitForm: function(){
            this.search(this.currentQuery);
        },
        clearTextField: function() {
            this.currentQuery = '';
        },
        resetFilter: function() {
            this.$store.commit({
                type: 'resetFilters',
                payload: {}
		    });
            this.updateSearchResults();
        },     
        updateSearchResults: function(){
            this.$store.commit({
                type: 'setSearchInfo',
                payload: { 
                    currentPage: 1,
                    timestamp: Date.now(),
                }
            });
            this.$router.push({query: this.$store.getters.urlQuery});
        },
        onQueryChange() {
            if(this.queryInputDelayTimeout){
                clearTimeout(this.queryInputDelayTimeout);                
            }
            this.queryInputDelayTimeout = setTimeout(() => {
                this.search(this.currentQuery);             
            }, 500);
        }
    },
    created() {
        this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'setQuery') {
                this.currentQuery = state.searchInfo.query;
            }    
        });
    },
     
};
</script>