<template>    
        <a :href="hit.url" class="jk-event-puff__link jk-events-listing__hit-item jk-event-puff env-m-bottom--medium" :title="'Läs mer om ' +hit.title + ' - ' +hit.dateText">
            
                <div role="img" :aria-label="hit.imageAltText" class="jk-event-puff__image" :style="[hit.imageUrl ? { backgroundImage : 'url(' + encodeURI(hit.imageUrl) + ')' } : {}]"></div>
                <div class="jk-event-puff__date">
                    <div>{{hit.dateText}}</div>
                </div>
                <div class="env-p-around--xx-small env-h--100 env-d--flex env-flex--column">
                    <small class="jk-event-puff__location env-text--uppercase env-m-top--small">{{hit.location}}</small>
                    <div>
                        <span class="rubrikx1">{{hit.title}}</span>
                    </div>
                    <div class="jk-event-puff__labels">
                        <span class="material-icons-outlined">local_offer</span>
                        <div>{{itemCategoriesText}}</div>
                    </div>
                </div>            
        </a>
    
</template>

<script >
import Vue from 'vue';

export default Vue.extend({
    name: "SearchHit",
    props: {
        hit: Object,
        index: Number
    },
    computed: {
        translation() { return this.$store.getters.translation },
        itemCategoriesText() { 
            return this.hit.categories.join (' | ');
        },
    }
});
</script>

