export interface IHit {
	id: string,
	title: string,
	imageUrl: string,
	imageAltText: string,
	location: string,
	categories: Array<string>,
	url: string,
	price: string,
  }
  
  export interface IFilterOption {
	id: string,
	title: string,
	value: boolean
  }

  export interface IInitFilterOption {
	id: string,
	title: string,
  }  
  
  export interface IFilters {
	category: IFilterOption[],
	area: IFilterOption[],
	age: IFilterOption[],
	theme: IFilterOption[],
	dates: {
		startdate?: number;
		enddate?: number;
	}
  }
  
  export interface IState {
	searching:boolean,
	filtersConfigured:boolean,
	searchHits: Array<IHit>,
	searchInfo: {
	  query: string,
	  totalHits: number,
	  totalPages: number,
	  currentPage: number,
	  timestamp: number,
	},
	filter_options: {
	  category: Array<IInitFilterOption>,
	  area: Array<IInitFilterOption>,
	  age: Array<IInitFilterOption>,
	  theme: Array<IInitFilterOption>
	},
	filters: IFilters,
	showFilters: boolean
	app: {
	  submitUrl: string,
	},
	translation: {};
  }
  
  const deafultState: IState = {
	searching:true,
	filtersConfigured: false,
	searchHits: [],
	searchInfo: {
	  query: "",
	  totalHits: 0,
	  totalPages: 0,
	  currentPage: 1,
	  timestamp: 0,
	},
	filter_options: {
		category: [],
		area: [],
		age: [],		
		theme: [],
	},
	showFilters: true,
	filters: {
		category: [],
		area: [],
		age: [],
		theme: [],
		dates: {
			startdate: undefined,
			enddate: undefined
	  	}	  
	},
	app: {
	  submitUrl: ""
	},
	translation: {},
  };
  
  export default deafultState;
  